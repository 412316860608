.productCenter {
    width: 100%;
    margin: auto;

    &-catalogue {
        width: 100%;
        padding: 40px 0;
        margin: auto;
        height: 480px;
        background-image: url(../../assets/images/bjcp.jpg);
        background-size: 100% auto;
        margin-bottom: 40px;
    }

    &-item-header {
        width: 100%;
        background: $cpbjColor;
        border-bottom: 2px rgba(141, 141, 141, 0.1) solid;
        box-sizing: border-box;
        position: relative;
        z-index: 9;
        color: #fff;

        &-title {
            width: var(--body-width);
            margin: auto;
            display: flex;
            flex-wrap: wrap;

            >div {
                display: inline-flex;
                align-items: center;
                border-bottom: 4px rgb(157, 191, 228) solid;
                margin-bottom: -2px;

                span {
                    font-size: 20px;
                    margin-left: 8px;
                    margin-top: 10px;
                    font-weight: 300;
                    color: #8d8d8d;
                }

                p {
                    font-size: 24px;
                    margin: 12px 0;
                }
            }
        }
    }

    &-body {
        width: 100%;
        &-MP{
            width: 100%;
            &-effect{
                width: 840px;
                margin: auto;
                padding:120px ;
            }
            &-content {
                width: var(--body-width);
                margin: auto;
                text-indent: 2em;
                display: flex;
                align-items: center;
                &-img {
                    width: 65%;
                    display: flex;
                    padding: 20px;
                    .el-image {
                        padding: 0 5px;
                        width: 100%;
                        .image-slot {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 400px;
                            background: var(--el-text-color-placeholder);
                            color: var(--el-text-color-regular);
                            font-size: 30px;
        
                            .el-icon {
                                color: var(--el-text-color-regular);
                                font-size: 30px;
                            }
                        }
                    }
                }
        
                &-text {
                    width: 35%;
                    padding: 20px;
                    height: 100%;
                }
            }
            &-use{
                padding:120px;
                width: var(--body-width);
                align-items: center;
                margin: auto;
                display: flex;
                &-img {
                    width: 65%;
                    display: flex;
                    padding: 20px;
                    .el-image {
                        padding: 0 5px;
                        width: 100%;
                        .image-slot {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 400px;
                            background: var(--el-text-color-placeholder);
                            color: var(--el-text-color-regular);
                            font-size: 30px;
        
                            .el-icon {
                                color: var(--el-text-color-regular);
                                font-size: 30px;
                            }
                        }
                    }
                }
                &-text {
                    width: 35%;
                    padding: 20px;
                    height: 100%;
                }
            }
        }
        &-YQ{
            width: 100%;
        }
    }

    

    &-navigation {
        position: fixed;
        top: 50%;
        left: 20px;
        // width: 200px;
        box-sizing: border-box;
        // height: 240px;
        // background: #8d8d8d;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        z-index: 10;

        li {
            display: block;
            color: rgba(68, 68, 68, 0.6);
            font-size: 14px;
            height: 40px;
            display: flex;
            width: 100%;
            align-items: center;
            padding-bottom: 10px;
            position: relative;

            >span {
                cursor: pointer;
            }
        }

        &-indicator {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                width: 6px;
                height: 6px;
                background: rgba(68, 68, 68, .6);
                border-radius: 50%;
            }
        }



        @keyframes fs-nav-animation {
            0% {
                transform: scale(0);
            }

            50% {
                transform: scale(1.8);
            }

            55% {
                transform: scale(0);
            }

            100% {
                transform: scale(0);
            }
        }

        @keyframes bg-nav-animation {
            0% {
                transform: scale(1);
            }

            40% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.1);
            }

            70% {
                transform: scale(.8);
            }

        }

        .active {
            font-weight: 600;
            font-size: 16px;
            color: rgb(68, 68, 68);
            border-color: #000;
            position: relative;
            display: flex;
            align-items: center;

            .productCenter-navigation-indicator::after {
                position: absolute;
                content: '';
                background: #fff;
                width: 10px;
                height: 10px;
                animation: fs-nav-animation 1.2s ease-out normal infinite;
                border-radius: 50%;
                box-sizing: content-box;
                transition: all 0.2s ease-in-out;
                position: absolute;
            }

            .productCenter-navigation-indicator {
                span {
                    background: #000;
                    width: 16px;
                    height: 16px;
                    animation: bg-nav-animation 1.2s ease-out normal infinite;
                }
            }
        }


        li:hover {
            font-size: 16px;
            color: rgb(68, 68, 68);
        }

    }
    .el-skeleton__item{
        background: var(--el-text-color-placeholder);
    }
}