@import "./src/assets/styles/index.scss";

@import '@/views/productCenter/index.scss';
.companyProfile{
    width: 100%;
    &-catalogue{
        width: 100%;
        height: 60vh;
        background: $cpbjColor;
        margin-bottom: 80px;
    }
    &-content{
        width: var(--body-width);
        margin: auto;
        padding: 60px 0;
        display: flex;
        >div{
            padding: 0 40px;
        }
    }
    &-content:nth-child(2n + 1){
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}
